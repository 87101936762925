$(document).ready(function () {
    $('#loading-content').remove();
    $('#site-maincontent').css({'opacity': 1});

    $('#intro').unwrap();
    var vimeoUrl = $("#vimeo iframe").attr('src');
    $('#vimeo').on('hidden.bs.modal', function (e) {
        $("#vimeo iframe").attr('src', '');
    });
    $('#vimeo').on('show.bs.modal', function (e) {
        $("#vimeo iframe").attr('src', vimeoUrl);
    });
});